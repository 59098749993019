<template>
    <div>
        <video ref="videoPlayer" v-if="type === 'video'" class="video-js vjs-fluid vjs-big-play-centered"></video>
        <vue-plyr v-else>
            <audio controls crossorigin playsinline>
                <source
                    :src="src"
                    type="audio/mp3"
                />
            </audio>
        </vue-plyr>
    </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import 'videojs-hls-quality-selector'
import "videojs-contrib-quality-levels"

export default {
    name: "VideoPlayer",
    props: {
        type: {
            type: String,
            default: 'video'
        },
        src: {
            required: true
        },
        poster: {
            type: String
        },
        options: {
            type: Object,
            default() {
                return {
                    autoplay: false,
                    controls: true,
                    responsive: true,
                    sources: [
                        {
                            src: this.src,
                            type: "application/x-mpegURL"
                        }
                    ],
                    poster: this.poster,
                    plugins: {
                        hlsQualitySelector: {
                            displayCurrentQuality: true,
                        }
                    },
                    html5: {
                        nativeAudioTracks: true,
                        nativeVideoTracks: true,
                        nativeTextTracks: true,
                        hls: {
                            overrideNative: true,
                        },
                    }
                };
            }
        }
    },
    data() {
        return {
            player: null
        }
    },
    mounted() {
        this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
            console.log('onPlayerReady', this);
        })
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose()
        }
    }
}
</script>
