<template>
    <div class="bg-gray-300 rounded p-4">
        <h4 class="mb-3">{{ materialItem.title }}</h4>
        <button type="button" class="flex items-center justify-center w-full whitespace-nowrap overflow-hidden rounded bg-gray-200 text-xs px-4 py-2 hover:bg-gray-300" @click.prevent="downloadFile(materialItem.link)">
            Скачать
            <svg viewBox="0 0 477.867 477.867" class="fill-current h-3 w-3 ml-2">
                <path d="M443.733,307.2c-9.426,0-17.067,7.641-17.067,17.067v102.4c0,9.426-7.641,17.067-17.067,17.067H68.267
			c-9.426,0-17.067-7.641-17.067-17.067v-102.4c0-9.426-7.641-17.067-17.067-17.067s-17.067,7.641-17.067,17.067v102.4
			c0,28.277,22.923,51.2,51.2,51.2H409.6c28.277,0,51.2-22.923,51.2-51.2v-102.4C460.8,314.841,453.159,307.2,443.733,307.2z"/>
                <path d="M335.947,295.134c-6.614-6.387-17.099-6.387-23.712,0L256,351.334V17.067C256,7.641,248.359,0,238.933,0
			s-17.067,7.641-17.067,17.067v334.268l-56.201-56.201c-6.78-6.548-17.584-6.36-24.132,0.419c-6.388,6.614-6.388,17.099,0,23.713
			l85.333,85.333c6.657,6.673,17.463,6.687,24.136,0.031c0.01-0.01,0.02-0.02,0.031-0.031l85.333-85.333
			C342.915,312.486,342.727,301.682,335.947,295.134z"/>
            </svg>
        </button>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Material",

    props: {
        material: {
            required: true
        }
    },

    computed: {
        materialItem() {
            return JSON.parse(this.material)
        }
    },

    methods: {
        async downloadFile(path) {
            axios.get(`/api/get-file/${path}`, {responseType: 'blob'}).then(response => {
                console.log('response', response.data)
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.pdf');
                document.body.appendChild(link);
                link.click();
            })
            // console.log('data', data)
        },
    }
}
</script>
