<template>
    <div>
        <label class="block text-sm font-medium text-gray-700">{{ field.title }}</label>
        <div class="mt-1 relative rounded-md shadow-sm">
            <textarea :name="field.name"
                      class="focus:ring-indigo-500 resize-none focus:border-indigo-500 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                      placeholder="" v-model="string"></textarea>
        </div>
    </div>
</template>

<script>
import {FIELDS_METHODS} from "./mixins";

export default {
    name: "TextField",

    mixins: [FIELDS_METHODS],

    computed: {
        string: {
            get() {
                return this.value.value
            },
            set(val) {
                this.value.value = val
                this.$emit('updateField', this.value)
            }
        }
    }
}
</script>
