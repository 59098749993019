<template>
    <div class="-mr-2 -my-2 md:hidden">
        <button type="button"
                @click="isOpened = !isOpened"
                class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span class="sr-only">Open menu</span>
            <!-- Heroicon name: outline/menu -->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h16"/>
            </svg>
        </button>
        <transition name="fade">
            <div v-if="isOpened" @click="isOpened = !isOpened" class="fixed w-full h-full inset-0 bg-black bg-opacity-20 z-40"></div>
        </transition>
        <transition name="slide-right">
            <div v-if="isOpened"
                class="fixed w-72 bg-white border-l border-gray-200 shadow-md top-0 right-0 overflow-y-auto h-full z-40">
                <div class="mt-4">
                    <a :href="profileUrl" class="block mx-auto text-center" v-if="auth">
                        <img class="rounded-full w-20 h-20 border-4 border-red-400 mx-auto" :src="`/storage/images/${avatar.trim()}`" alt=""/>
                    </a>
                    <div class="px-4 pt-4">
                        <div class="flex justify-center border-b pb-4 border-gray-200">
                            <template v-if="auth">
                                <a :href="profileUrl" class="flex items-center text-gray-700 hover:text-red-400 tracking-wider text-lg">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-4 h-4 mr-2" viewBox="0 0 512 512">
                                        <defs/>
                                        <path d="M437.02 74.98C388.668 26.63 324.379 0 256 0 187.617 0 123.332 26.629 74.98 74.98 26.63 123.332 0 187.617 0 256c0 68.379 26.629 132.668 74.98 181.02C123.332 485.37 187.617 512 256 512c68.379 0 132.668-26.629 181.02-74.98C485.37 388.668 512 324.379 512 256c0-68.383-26.629-132.668-74.98-181.02zM128.34 442.387c10.707-61.649 64.129-107.121 127.66-107.121 63.535 0 116.953 45.472 127.66 107.12C347.312 467.36 303.336 482 256 482s-91.313-14.64-127.66-39.613zm46.262-218.52c0-44.887 36.515-81.398 81.398-81.398s81.398 36.515 81.398 81.398c0 44.883-36.515 81.399-81.398 81.399s-81.398-36.516-81.398-81.399zm235.043 197.711c-8.075-28.7-24.11-54.738-46.586-75.078a159.444 159.444 0 00-46.36-29.27c30.5-19.894 50.703-54.312 50.703-93.363 0-61.426-49.976-111.398-111.402-111.398S144.602 162.44 144.602 223.867c0 39.051 20.203 73.469 50.699 93.363a159.483 159.483 0 00-46.36 29.266c-22.472 20.34-38.511 46.379-46.586 75.078C57.883 380.274 30 321.336 30 256 30 131.383 131.383 30 256 30s226 101.383 226 226c0 65.34-27.883 124.277-72.355 165.578zm0 0"/>
                                    </svg>
                                    Профиль
                                </a>
                                <span class="px-3">|</span>
                                <logout-link
                                    class="flex items-center text-gray-700 hover:text-red-400 tracking-wider text-lg"
                                    :logout-url="logoutUrl">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-4 h-4 mr-2" viewBox="0 0 24 24">
                                        <defs/>
                                        <path d="M13.5 21h-4a.5.5 0 010-1h4c.827 0 1.5-.673 1.5-1.5v-5a.5.5 0 011 0v5c0 1.378-1.121 2.5-2.5 2.5zM23.5 11h-10a.5.5 0 010-1h10a.5.5 0 010 1z"/>
                                        <path d="M8 24c-.22 0-.435-.037-.638-.109l-5.99-1.997A1.998 1.998 0 010 20V2C0 .897.897 0 2 0c.222 0 .438.037.639.11l5.989 1.996A1.996 1.996 0 0110 4v18c0 1.103-.897 2-2 2zM2 1c-.552 0-1 .449-1 1v18a1 1 0 00.688.946l6 2C8.344 23.179 9 22.654 9 22V4a.996.996 0 00-.688-.945l-6-2A.92.92 0 002 1z"/>
                                        <path d="M15.5 8a.5.5 0 01-.5-.5v-5c0-.827-.673-1.5-1.5-1.5H2a.5.5 0 010-1h11.5C14.879 0 16 1.122 16 2.5v5a.5.5 0 01-.5.5zM19.5 15a.5.5 0 01-.354-.853l3.646-3.646-3.646-3.646a.5.5 0 01.707-.707l4 4a.5.5 0 010 .707l-4 4A.501.501 0 0119.5 15z"/>
                                    </svg>
                                    Выход
                                </logout-link>
                            </template>
                            <template v-else>
                                <a :href="loginUrl" class="flex items-center text-gray-700 hover:text-red-400 tracking-wider text-lg">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-4 h-4 mr-2" viewBox="0 0 512 512">
                                        <defs/>
                                        <path d="M437.02 74.98C388.668 26.63 324.379 0 256 0 187.617 0 123.332 26.629 74.98 74.98 26.63 123.332 0 187.617 0 256c0 68.379 26.629 132.668 74.98 181.02C123.332 485.37 187.617 512 256 512c68.379 0 132.668-26.629 181.02-74.98C485.37 388.668 512 324.379 512 256c0-68.383-26.629-132.668-74.98-181.02zM128.34 442.387c10.707-61.649 64.129-107.121 127.66-107.121 63.535 0 116.953 45.472 127.66 107.12C347.312 467.36 303.336 482 256 482s-91.313-14.64-127.66-39.613zm46.262-218.52c0-44.887 36.515-81.398 81.398-81.398s81.398 36.515 81.398 81.398c0 44.883-36.515 81.399-81.398 81.399s-81.398-36.516-81.398-81.399zm235.043 197.711c-8.075-28.7-24.11-54.738-46.586-75.078a159.444 159.444 0 00-46.36-29.27c30.5-19.894 50.703-54.312 50.703-93.363 0-61.426-49.976-111.398-111.402-111.398S144.602 162.44 144.602 223.867c0 39.051 20.203 73.469 50.699 93.363a159.483 159.483 0 00-46.36 29.266c-22.472 20.34-38.511 46.379-46.586 75.078C57.883 380.274 30 321.336 30 256 30 131.383 131.383 30 256 30s226 101.383 226 226c0 65.34-27.883 124.277-72.355 165.578zm0 0"/>
                                    </svg>
                                    Вход
                                </a>
                                <span class="px-3">|</span>
                                <a :href="registerUrl" class="flex items-center text-gray-700 hover:text-red-400 tracking-wider text-lg">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-4 h-4 mr-2" viewBox="0 0 512 512">
                                        <defs/>
                                        <path d="M218.668 240H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h202.668c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0"/>
                                        <path d="M138.668 320a15.89 15.89 0 01-11.309-4.691c-6.25-6.254-6.25-16.387 0-22.637l68.696-68.692-68.696-68.671c-6.25-6.254-6.25-16.387 0-22.637s16.383-6.25 22.637 0l80 80c6.25 6.25 6.25 16.383 0 22.637l-80 80A15.975 15.975 0 01138.668 320zm0 0M341.332 512c-23.531 0-42.664-19.137-42.664-42.668v-384c0-18.238 11.605-34.516 28.883-40.512L455.723 2.09C484.395-6.7 512 14.57 512 42.668v384c0 18.219-11.605 34.473-28.863 40.488L354.922 509.91c-4.672 1.45-9 2.09-13.59 2.09zm128-480c-1.387 0-2.559.172-3.816.555L337.879 75.113c-4.184 1.453-7.211 5.676-7.211 10.219v384c0 7.277 7.89 12.184 14.484 10.113l127.637-42.558c4.16-1.453 7.211-5.676 7.211-10.219v-384C480 36.801 475.223 32 469.332 32zm0 0"/>
                                        <path d="M186.668 106.668c-8.832 0-16-7.168-16-16v-32c0-32.363 26.3-58.668 58.664-58.668h240c8.832 0 16 7.168 16 16s-7.168 16-16 16h-240c-14.7 0-26.664 11.969-26.664 26.668v32c0 8.832-7.168 16-16 16zm0 0M314.668 448h-85.336c-32.363 0-58.664-26.305-58.664-58.668v-32c0-8.832 7.168-16 16-16s16 7.168 16 16v32c0 14.7 11.965 26.668 26.664 26.668h85.336c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0"/>
                                    </svg>
                                    Регистрация
                                </a>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="mt-4 p-4">
                    <a class="block w-full border-b border-gray-200 p-4 text-gray-700 hover:text-red-400 tracking-wider text-lg"
                       :href="link" v-for="(link, name) in menu">
                        {{ name }}
                    </a>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import axios from "axios";
import LogoutLink from "./LogoutLink";

export default {
    name: "MobileMenu",

    props: {
        avatar: {
            type: String
        },
        menu: {
            type: Array|Object
        },
        loginUrl: {
            type: String
        },
        registerUrl: {
            type: String
        },
        profileUrl: {
            type: String
        },
        dashboardUrl: {
            type: String
        },
        logoutUrl: {
            type: String
        }
    },

    components: {
        LogoutLink
    },

    data() {
        return {
            isOpened: false
        }
    },

    computed: {
        auth() {
            return !(this.avatar.trim() === 'unauthorized')
        }
    }
}
</script>
