<template>
    <div>
        <div v-for="item in settings">
            <component :is="item.type" :field="item" class="mt-3 border-b border-gray-300 pb-3"></component>
        </div>
        <div>
            <button type="button" class="block rounded bg-green-200 mt-4 px-4 py-2 hover:bg-green-300"
                    @click="onSubmit">
                Сохранить
            </button>
        </div>
        <notifications></notifications>
    </div>
</template>

<script>
import StringField from "./fields/StringField";
import TextField from "./fields/TextField";
import axios from "axios";

export default {
    name: "SettingsComponent",

    components: {
        'stringfield': StringField,
        'textfield': TextField
    },

    props: {
        content: {
            required: true
        }
    },

    data() {
        return {
            settings: this.content
        }
    },

    methods: {
        async onSubmit() {
            try {
                const {data} = await axios.post(`/api/settings/update`, {
                    settings: this.settings
                })
                this.$notify({
                    type: 'success',
                    title: 'Получилось!',
                    text: 'Блок изменен'
                });
                console.log('data', data)
            } catch (e) {
                this.$notify({
                    type: 'error',
                    title: 'Упс! Что-то пошло не так',
                    text: e
                });
            }
        }
    }
}
</script>

