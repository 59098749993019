<template>
    <div>
        <label class="block text-sm font-medium text-gray-700">{{ field.title }}</label>
        <div class="mt-1 relative rounded-md shadow-sm">
            <editor :content-prop.sync="string"></editor>
        </div>
    </div>
</template>

<script>
import Editor from "../common/Editor";
import {FIELDS_METHODS} from "./mixins";

export default {
    name: "EditorField",

    components: {
        Editor
    },

    mixins: [FIELDS_METHODS],

    computed: {
        string: {
            get() {
                return this.value.value
            },
            set(val) {
                this.value.value = val
                this.$emit('updateField', this.value)
            }
        }
    }
}
</script>
