<template>
    <vue-dropzone :ref="id" :name="name" :id="id" :disable-previews="false" :options="dropzoneOptions" :accepted-files="$attrs.accept" :max-files="$attrs.maxFiles"></vue-dropzone>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    name: "SimpleDropzone",

    props: {
        name: {
            required: true
        }
    },

    components: {
        vueDropzone: vue2Dropzone
    },

    computed: {
        id() {
            return this.$attrs.id ?? this.name
        }
    },

    data: function () {
        return {
            dropzoneOptions: {
                url: '/',
                autoProcessQueue: false
            }
        }
    }
}
</script>
