import { render, staticRenderFns } from "./FeaturesField.vue?vue&type=template&id=15df3f67"
import script from "./FeaturesField.vue?vue&type=script&lang=js"
export * from "./FeaturesField.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports