<template>
    <div class="flex items-center relative">
        <button type="button" class="block rounded bg-gray-200 text-xs w-full px-4 py-2 hover:bg-gray-300"
                v-if="!value"
                @click.prevent="$refs['file'].click()">Загрузить
            файл
        </button>
        <div v-else class="flex items-center w-full">
            <button type="button" class="flex items-center w-full whitespace-nowrap overflow-hidden rounded bg-gray-200 text-xs px-4 py-2 hover:bg-gray-300" @click.prevent="downloadFile">
                {{ value }}
                <svg viewBox="0 0 477.867 477.867" class="fill-current h-3 w-3 ml-2">
                    <path d="M443.733,307.2c-9.426,0-17.067,7.641-17.067,17.067v102.4c0,9.426-7.641,17.067-17.067,17.067H68.267
			c-9.426,0-17.067-7.641-17.067-17.067v-102.4c0-9.426-7.641-17.067-17.067-17.067s-17.067,7.641-17.067,17.067v102.4
			c0,28.277,22.923,51.2,51.2,51.2H409.6c28.277,0,51.2-22.923,51.2-51.2v-102.4C460.8,314.841,453.159,307.2,443.733,307.2z"/>
                        <path d="M335.947,295.134c-6.614-6.387-17.099-6.387-23.712,0L256,351.334V17.067C256,7.641,248.359,0,238.933,0
			s-17.067,7.641-17.067,17.067v334.268l-56.201-56.201c-6.78-6.548-17.584-6.36-24.132,0.419c-6.388,6.614-6.388,17.099,0,23.713
			l85.333,85.333c6.657,6.673,17.463,6.687,24.136,0.031c0.01-0.01,0.02-0.02,0.031-0.031l85.333-85.333
			C342.915,312.486,342.727,301.682,335.947,295.134z"/>
                </svg>
            </button>
        </div>
        <button type="button"
                v-if="value"
                class="block absolute left-auto -top-6 right-0 w-5 h-5 ml-3 bg-red-200 rounded flex flex-col items-center justify-center hover:bg-red-300"
                @click.prevent="remove">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-2 h-2 fill-current"
                 viewBox="0 0 512.001 512.001">
                <path
                    d="M294.111 256.001L504.109 46.003c10.523-10.524 10.523-27.586 0-38.109-10.524-10.524-27.587-10.524-38.11 0L256 217.892 46.002 7.894C35.478-2.63 18.416-2.63 7.893 7.894s-10.524 27.586 0 38.109l209.998 209.998L7.893 465.999c-10.524 10.524-10.524 27.586 0 38.109 10.524 10.524 27.586 10.523 38.109 0L256 294.11l209.997 209.998c10.524 10.524 27.587 10.523 38.11 0 10.523-10.524 10.523-27.586 0-38.109L294.111 256.001z"/>
            </svg>
        </button>
        <input type="file" class="hidden" hidden id="file" ref="file"
               name="file"
               @change="onChange"/>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "FileUploader",

    props: {
        value: {
            required: false
        }
    },

    methods: {
        onChange(event) {
            if (event.target?.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    this.file = e.target.result;
                    this.upload(event.target?.files[0])
                }
                reader.readAsDataURL(event.target?.files[0]);
            }
        },
        upload(file) {
            let formData = new FormData();
            formData.append('file', file);
            axios.post('/api/private-upload-file',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(res => {
                this.filename = res.data.path
                this.$emit('update:value', this.filename)
            })
                .catch(function (e) {
                    console.log('FAILURE!!', e);
                });
        },
        async downloadFile() {
            axios.get(`/api/get-file/${this.value}`, {responseType: 'blob'}).then(response => {
                console.log('response', response.data)
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.pdf');
                document.body.appendChild(link);
                link.click();
            })
            // console.log('data', data)
        },
        async remove() {
            this.filename = null
            this.$emit('update:value', this.filename)
        }
    }
}
</script>
