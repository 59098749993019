<template>
    <div>
        <label :for="field.name" class="block text-sm font-medium text-gray-700">{{ field.title }}</label>
        <div class="flex mt-1">
            <div class="rounded mr-2 shadow p-2 w-1/3 bg-gray-50" v-for="(button, i) in value.value" :key="i">
                <div class="relative rounded-md shadow-sm">
                    <label class="block text-sm font-medium text-gray-700">Текст на кнопке</label>
                    <input type="text" v-model="button.title"
                           class="focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                           placeholder="">
                </div>
                <div class="relative rounded-md shadow-sm mt-3">
                    <label class="block text-sm font-medium text-gray-700">Тип кнопки</label>
                    <select
                        class="focus:ring-indigo-500 bg-gray-100 cursor-not-allowed focus:border-indigo-500 mt-1 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                        disabled
                        v-model="button.type">
                        <option value="link">Ссылка</option>
                        <option value="button">Кнопка</option>
                    </select>
                </div>
                <div class="relative rounded-md shadow-sm mt-3" v-if="button.type === 'link'">
                    <label class="block text-sm font-medium text-gray-700">Адрес ссылки</label>
                    <input type="text" v-model="button.link"
                           class="focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                           placeholder="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FIELDS_METHODS } from './mixins'
export default {
    name: "ButtonsField",

    mixins: [FIELDS_METHODS],

    watch: {
        value: {
            deep: true,
            handler: function (val) {
                this.$emit('updateField', val)
            }
        }
    }
}
</script>
