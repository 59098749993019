<template>
    <div>
        <label class="block text-sm font-medium text-gray-700">{{ label }}</label>
        <div class="w-full mt-1" v-if="files">
            <div class="w-full pr-2" v-for="(file, i) in files" :key="i">
                <input type="hidden" :name="name" v-if="file.file_id" v-model="file.file_id">
                <div class="p-4 bg-gray-100 rounded-md flex items-center relative">
                    <div class="mr-4 w-24">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-24 h-24" viewBox="0 0 480 480"
                             v-if="file.type.includes('video')">
                            <path d="M8 40h464v400H8V40z" fill="#4398d1"/>
                            <path d="M64 440h408V40h-24L64 440z" fill="#3e8cc7"/>
                            <g fill="#3570ab">
                                <path d="M8 48h64v384H8V48zM408 48h64v384h-64V48z"/>
                            </g>
                            <path d="M184 176v128l112-64-112-64z" fill="#fdb62f"/>
                            <g fill="#4398d1">
                                <path
                                    d="M0 440h80V40H0v400zm16-16v-48h48v48H16zm48-240v48H16v-48h48zm-48-16v-48h48v48H16zm48 80v48H16v-48h48zm0 64v48H16v-48h48zm0-256v48H16V56h48zM400 40v400h80V40h-80zm64 16v48h-48V56h48zm-48 240v-48h48v48h-48zm48 16v48h-48v-48h48zm-48-80v-48h48v48h-48zm0-64v-48h48v48h-48zm0 256v-48h48v48h-48z"/>
                            </g>
                            <path d="M184 275.92V304l112-64-43.712-24.976A396.784 396.784 0 01184 275.92z"
                                  fill="#ffa83d"/>
                            <path
                                d="M400 40H0v400h480V40h-80zM64 56v48H16V56h48zM16 296v-48h48v48H16zm48 16v48H16v-48h48zm-48-80v-48h48v48H16zm0-64v-48h48v48H16zm0 256v-48h48v48H16zm384 0H80V56h320v368zm16-128v-48h48v48h-48zm48 16v48h-48v-48h48zm-48-80v-48h48v48h-48zm0-64v-48h48v48h-48zm0 256v-48h48v48h-48zm48-368v48h-48V56h48z"/>
                            <path
                                d="M180 310.92a8 8 0 008 0l112-64a8 8 0 000-13.888l-112-64A8 8 0 00176 176v128a8 8 0 004 6.92zm12-121.128L279.872 240 192 290.208V189.792z"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                             class="w-24 h-24" v-if="file.type.includes('audio')" viewBox="0 0 415.75 664.28">
                            <defs>
                                <linearGradient id="c">
                                    <stop offset="0" stop-color="#0f0"/>
                                    <stop offset="1" stop-color="#005300"/>
                                </linearGradient>
                                <linearGradient id="a">
                                    <stop offset="0" stop-color="#d8d8d8"/>
                                    <stop offset="1" stop-color="#4c4c4c"/>
                                </linearGradient>
                                <linearGradient id="t" y2="694.96" gradientUnits="userSpaceOnUse" x2="401" y1="639.15"
                                                x1="401">
                                    <stop offset="0"/>
                                    <stop offset=".807" stop-color="#a1a1a1"/>
                                    <stop offset="1" stop-color="#d3d3d3" stop-opacity="0"/>
                                </linearGradient>
                                <linearGradient id="h" y2="325.75" gradientUnits="userSpaceOnUse" x2="647.72"
                                                y1="652.37" x1="623.3">
                                    <stop offset="0" stop-color="#252525"/>
                                    <stop offset="1" stop-color="#7e7e7e"/>
                                </linearGradient>
                                <linearGradient id="g" y2="652.37" gradientUnits="userSpaceOnUse" x2="623.3" y1="326.65"
                                                x1="623.3">
                                    <stop offset="0" stop-color="#dddcd5"/>
                                    <stop offset="1" stop-color="#dddcd5" stop-opacity="0"/>
                                </linearGradient>
                                <linearGradient id="i" y2="960.96" gradientUnits="userSpaceOnUse" x2="397.84" y1="840.8"
                                                x1="398">
                                    <stop offset="0" stop-color="#0d0d0d"/>
                                    <stop offset="1" stop-color="#0d0d0d" stop-opacity="0"/>
                                </linearGradient>
                                <linearGradient id="k" y2="254.13" gradientUnits="userSpaceOnUse" x2="469.73"
                                                y1="530.34" x1="296.32">
                                    <stop offset="0" stop-color="#d4aa00"/>
                                    <stop offset="1" stop-color="#d4aa00" stop-opacity="0"/>
                                </linearGradient>
                                <linearGradient id="j" y2="446.27" gradientUnits="userSpaceOnUse" x2="426.76"
                                                y1="446.27" x1="177.31">
                                    <stop offset="0" stop-color="#626262"/>
                                    <stop offset="1" stop-color="#626262" stop-opacity="0"/>
                                </linearGradient>
                                <linearGradient id="l" y2="921.13" gradientUnits="userSpaceOnUse" x2="601.24"
                                                y1="782.76" x1="603.36">
                                    <stop offset="0" stop-color="#3f3f3f"/>
                                    <stop offset="1" stop-opacity="0"/>
                                </linearGradient>
                                <linearGradient id="n" y2="625.58" gradientUnits="userSpaceOnUse" x2="618.7" y1="598.28"
                                                x1="618.7">
                                    <stop offset="0" stop-color="#dddcd5"/>
                                    <stop offset="1" stop-color="#dddcd5" stop-opacity="0"/>
                                </linearGradient>
                                <linearGradient id="o" y2="846.58" gradientUnits="userSpaceOnUse" x2="608.67"
                                                y1="546.99" x1="659.49">
                                    <stop offset="0" stop-color="#626262"/>
                                    <stop offset="1" stop-color="#626262" stop-opacity="0"/>
                                </linearGradient>
                                <linearGradient id="u" y2="652.37" xlink:href="#a" gradientUnits="userSpaceOnUse"
                                                x2="623.3" y1="326.65" x1="623.3"/>
                                <linearGradient id="w" y2="188.3" gradientUnits="userSpaceOnUse" x2="384.83" y1="209.89"
                                                x1="387.55">
                                    <stop offset="0" stop-color="#626262"/>
                                    <stop offset="1" stop-color="#c9c9c9"/>
                                </linearGradient>
                                <linearGradient id="v" y2="220.61" gradientUnits="userSpaceOnUse" x2="401.54" y1="190.5"
                                                x1="373.1">
                                    <stop offset="0" stop-color="#393939"/>
                                    <stop offset="1" stop-color="#393939" stop-opacity="0"/>
                                </linearGradient>
                                <linearGradient id="f" y2="125.7" gradientUnits="userSpaceOnUse" x2="378.32"
                                                gradientTransform="translate(9.082 -2.336)" y1="225.12" x1="379.49">
                                    <stop offset="0"/>
                                    <stop offset="1" stop-color="#7e7e7e"/>
                                </linearGradient>
                                <linearGradient id="b">
                                    <stop offset="0" stop-color="#48463a"/>
                                    <stop offset="1" stop-color="#bdbbad"/>
                                </linearGradient>
                                <linearGradient id="e" y2="733.71" gradientUnits="userSpaceOnUse" x2="364.51"
                                                y1="88.383" x1="364.51">
                                    <stop offset="0" stop-color="gray"/>
                                    <stop offset=".05" stop-color="#dbdbdb" stop-opacity=".937"/>
                                    <stop offset=".125" stop-color="gray" stop-opacity=".875"/>
                                    <stop offset=".25" stop-color="gray" stop-opacity=".749"/>
                                    <stop offset=".5" stop-color="gray" stop-opacity=".498"/>
                                    <stop offset="1" stop-color="gray" stop-opacity="0"/>
                                </linearGradient>
                                <linearGradient id="y" y2="714.49" gradientUnits="userSpaceOnUse" x2="401" y1="639.15"
                                                x1="401">
                                    <stop offset="0"/>
                                    <stop offset=".89" stop-color="#a1a1a1"/>
                                    <stop offset="1" stop-color="#d3d3d3" stop-opacity="0"/>
                                </linearGradient>
                                <linearGradient id="B" y2="525.67" xlink:href="#c" gradientUnits="userSpaceOnUse"
                                                x2="69.842" y1="517.67" x1="68.07"/>
                                <radialGradient id="p" gradientUnits="userSpaceOnUse" cy="492.84" cx="611.3"
                                                gradientTransform="matrix(.46371 -1.062 1.067 .46586 -201.28 912.34)"
                                                r="5.321">
                                    <stop offset="0" stop-color="#48463a"/>
                                    <stop offset="1" stop-color="#bdbbad"/>
                                </radialGradient>
                                <radialGradient id="q" gradientUnits="userSpaceOnUse" cy="489.46" cx="607.35"
                                                gradientTransform="matrix(.24051 1.2276 -1.0297 .17731 965.57 -344.26)"
                                                r="3.729">
                                    <stop offset="0" stop-color="#d8d8d8"/>
                                    <stop offset="1" stop-color="#4c4c4c"/>
                                </radialGradient>
                                <radialGradient id="r" gradientUnits="userSpaceOnUse" cy="489.46" cx="607.35"
                                                gradientTransform="matrix(.24051 1.2276 -1.0297 .17731 965.57 -344.26)"
                                                r="3.729">
                                    <stop offset="0" stop-color="#d8d8d8"/>
                                    <stop offset="1" stop-color="#2b2b2b"/>
                                </radialGradient>
                                <radialGradient id="s" xlink:href="#a" gradientUnits="userSpaceOnUse" cy="489.46"
                                                cx="607.35"
                                                gradientTransform="matrix(.24051 1.2276 -1.0297 .17731 965.57 -344.26)"
                                                r="3.729"/>
                                <radialGradient id="z" xlink:href="#b" gradientUnits="userSpaceOnUse" cy="492.84"
                                                cx="611.3"
                                                gradientTransform="matrix(.46371 -1.062 1.067 .46586 -201.28 912.34)"
                                                r="5.321"/>
                                <filter id="m" color-interpolation-filters="sRGB">
                                    <feGaussianBlur stdDeviation="1.042"/>
                                </filter>
                                <filter id="x" height="1.809" width="1.141" color-interpolation-filters="sRGB" y="-.404"
                                        x="-.07">
                                    <feGaussianBlur stdDeviation="9.308"/>
                                </filter>
                                <filter id="d" color-interpolation-filters="sRGB">
                                    <feGaussianBlur stdDeviation="2.471"/>
                                </filter>
                                <filter id="A" height="1.84" width="1.84" y="-.42" x="-.42">
                                    <feGaussianBlur stdDeviation="1.506"/>
                                </filter>
                            </defs>
                            <g transform="translate(-173.38 -76.123)">
                                <path
                                    d="M221.5 80.781c-26.634 0-48.062 17.666-48.062 39.625v575.16c0 21.959 21.429 39.625 48.062 39.625H541c26.634 0 48.062-17.666 48.062-39.625v-575.16c0-21.959-21.429-39.625-48.062-39.625H221.5z"
                                    stroke-linejoin="round" color="#000" stroke="#e7e7e7" stroke-linecap="round"
                                    stroke-width=".036" fill="#4d4d4d"/>
                                <rect transform="matrix(1.0699 0 0 1 -26.649 0)" rx="5" ry="5.198" height="652.42"
                                      width="335.88" y="82.052" x="213.31" opacity=".563" color="#000" filter="url(#d)"
                                      fill="url(#e)"/>
                                <rect rx="5" ry="5" height="100.98" width="100.98" y="154.32" x="330.76" opacity=".703"
                                      color="#000" fill="url(#f)"/>
                                <path
                                    d="M768.73 511.93c0 78.663-63.769 142.43-142.43 142.43-78.663 0-142.43-63.769-142.43-142.43 0-78.663 63.769-142.43 142.43-142.43 78.663 0 142.43 63.769 142.43 142.43z"
                                    transform="translate(-291.465 -83.911) scale(1.0741)" stroke-linejoin="round"
                                    color="#000" stroke="url(#g)" stroke-linecap="round" stroke-width="2.793"
                                    fill="url(#h)"/>
                                <path
                                    d="M452.55 891.75c0 30.126-24.422 54.548-54.548 54.548s-54.548-24.422-54.548-54.548 24.422-54.548 54.548-54.548 54.548 24.422 54.548 54.548z"
                                    transform="translate(-589.125 -1708.2) scale(2.4381)" stroke-linejoin="round"
                                    color="#000" stroke="#000" stroke-linecap="round" stroke-width=".482"
                                    fill="url(#i)"/>
                                <path
                                    d="M424.26 446.27c0 67.505-54.724 122.23-122.23 122.23-67.505 0-122.23-54.724-122.23-122.23 0-67.505 54.724-122.23 122.23-122.23 67.505 0 122.23 54.724 122.23 122.23z"
                                    transform="translate(122.375 83.459) scale(.8571)" stroke-linejoin="round"
                                    color="#000" stroke="url(#j)" stroke-linecap="round" stroke-width="5.001"
                                    fill="url(#k)"/>
                                <g color="#000">
                                    <path
                                        d="M674.78 853.36c0 37.937-30.754 68.69-68.69 68.69-37.937 0-68.69-30.754-68.69-68.69 0-37.937 30.754-68.69 68.69-68.69 37.937 0 68.69 30.754 68.69 68.69z"
                                        transform="translate(-75.545 -177.195) scale(.75368)" stroke="url(#l)"
                                        stroke-width="2.654" fill="#212121"/>
                                    <path
                                        d="M642.46 612.95c0 8.647-11.646 15.657-26.011 15.657-14.366 0-26.011-7.01-26.011-15.657s11.646-15.657 26.011-15.657c14.366 0 26.011 7.01 26.011 15.657z"
                                        transform="matrix(1.3632 0 0 1.3755 -459.565 -396.6)" filter="url(#m)"
                                        fill="url(#n)"/>
                                </g>
                                <path
                                    d="M786.66 679.2c0 74.596-60.472 135.07-135.07 135.07-74.596 0-135.07-60.472-135.07-135.07 0-74.596 60.472-135.07 135.07-135.07 74.596 0 135.07 60.472 135.07 135.07z"
                                    transform="translate(-270.335 -213.24)" stroke-linejoin="round"
                                    stroke-opacity=".796" color="#000" stroke="url(#o)" stroke-linecap="round"
                                    stroke-width="2" fill="none"/>
                                <path
                                    d="M612.94 491.62c0 3.117-2.382 5.644-5.32 5.644s-5.322-2.527-5.322-5.644 2.383-5.644 5.321-5.644 5.321 2.527 5.321 5.644z"
                                    transform="matrix(.83482 0 0 .86159 -127.665 188.53)" color="#000" fill="url(#p)"/>
                                <path
                                    d="M611.95 489.09c0 2.021-1.67 3.66-3.729 3.66s-3.729-1.639-3.729-3.66 1.67-3.66 3.73-3.66 3.728 1.639 3.728 3.66z"
                                    transform="matrix(1 0 0 1.0667 -228.645 89.579)" color="#000" fill="url(#q)"/>
                                <g stroke="#000" stroke-linecap="round" fill="none">
                                    <path d="m378.347 609.239 2.513 3.916M381.497 609.849l-3.792 2.695"/>
                                </g>
                                <path d="m379.495 611.91.879 1.38M378.825 609.03l.879 1.38" stroke="#e7e7e7"
                                      stroke-linecap="round" stroke-width=".05" fill="none"/>
                                <path
                                    d="M612.94 491.62c0 3.117-2.382 5.644-5.32 5.644s-5.322-2.527-5.322-5.644 2.383-5.644 5.321-5.644 5.321 2.527 5.321 5.644z"
                                    transform="matrix(.83482 0 0 .86159 -3.41 117.51)" color="#000" fill="url(#p)"/>
                                <path
                                    d="M611.95 489.09c0 2.021-1.67 3.66-3.729 3.66s-3.729-1.639-3.729-3.66 1.67-3.66 3.73-3.66 3.728 1.639 3.728 3.66z"
                                    transform="matrix(1 0 0 1.0667 -104.385 18.56)" color="#000" fill="url(#r)"/>
                                <g stroke="#000" stroke-linecap="round" fill="none">
                                    <path d="m504.088 537.858-.438 4.631M506.19 540.281l-4.647-.218"/>
                                </g>
                                <path d="m503.745 540.89.879 1.38M503.085 538.01l.879 1.38" stroke="#e7e7e7"
                                      stroke-linecap="round" stroke-width=".05" fill="none"/>
                                <path
                                    d="M612.94 491.62c0 3.117-2.382 5.644-5.32 5.644s-5.322-2.527-5.322-5.644 2.383-5.644 5.321-5.644 5.321 2.527 5.321 5.644z"
                                    transform="matrix(.83482 0 0 .86159 -2.155 -30.162)" color="#000" fill="url(#p)"/>
                                <path
                                    d="M611.95 489.09c0 2.021-1.67 3.66-3.729 3.66s-3.729-1.639-3.729-3.66 1.67-3.66 3.73-3.66 3.728 1.639 3.728 3.66z"
                                    transform="matrix(1 0 0 1.0667 -103.125 -129.11)" color="#000" fill="url(#s)"/>
                                <g stroke="#000" stroke-linecap="round" fill="none">
                                    <path d="m503.865 390.55 2.513 3.916M507.015 391.16l-3.792 2.695"/>
                                </g>
                                <path d="m505.005 393.22.879 1.38M504.335 390.34l.879 1.38" stroke="#e7e7e7"
                                      stroke-linecap="round" stroke-width=".05" fill="none"/>
                                <path
                                    d="M612.94 491.62c0 3.117-2.382 5.644-5.32 5.644s-5.322-2.527-5.322-5.644 2.383-5.644 5.321-5.644 5.321 2.527 5.321 5.644z"
                                    transform="matrix(.83482 0 0 .86159 -250.525 -30.245)" color="#000" fill="url(#p)"/>
                                <path
                                    d="M611.95 489.09c0 2.021-1.67 3.66-3.729 3.66s-3.729-1.639-3.729-3.66 1.67-3.66 3.73-3.66 3.728 1.639 3.728 3.66z"
                                    transform="matrix(1 0 0 1.0667 -351.505 -129.19)" color="#000" fill="url(#q)"/>
                                <g stroke="#000" stroke-linecap="round" fill="none">
                                    <path d="m257.36 390.186-1.241 4.484M259.006 392.94l-4.537-1.027"/>
                                </g>
                                <path d="m256.625 393.13.879 1.38M255.965 390.26l.879 1.38" stroke="#e7e7e7"
                                      stroke-linecap="round" stroke-width=".05" fill="none"/>
                                <path
                                    d="M612.94 491.62c0 3.117-2.382 5.644-5.32 5.644s-5.322-2.527-5.322-5.644 2.383-5.644 5.321-5.644 5.321 2.527 5.321 5.644z"
                                    transform="matrix(.83482 0 0 .86159 -249.505 117.44)" color="#000" fill="url(#p)"/>
                                <path
                                    d="M611.95 489.09c0 2.021-1.67 3.66-3.729 3.66s-3.729-1.639-3.729-3.66 1.67-3.66 3.73-3.66 3.728 1.639 3.728 3.66z"
                                    transform="matrix(1 0 0 1.0667 -350.485 18.489)" color="#000" fill="url(#q)"/>
                                <g stroke="#000" stroke-linecap="round" fill="none">
                                    <path d="m256.505 538.149 2.513 3.916M259.655 538.759l-3.792 2.695"/>
                                </g>
                                <path d="m257.655 540.82.879 1.38M256.985 537.94l.879 1.38" stroke="#e7e7e7"
                                      stroke-linecap="round" stroke-width=".05" fill="none"/>
                                <rect rx="25" ry="25" height="55.243" width="317.09" y="639.15" x="222.08"
                                      stroke-linejoin="round" color="#000" stroke="#4a4a4a" stroke-linecap="round"
                                      stroke-width="3" fill="url(#t)"/>
                                <path
                                    d="M612.94 491.62c0 3.117-2.382 5.644-5.32 5.644s-5.322-2.527-5.322-5.644 2.383-5.644 5.321-5.644 5.321 2.527 5.321 5.644z"
                                    transform="matrix(.83482 0 0 .86159 -126.75 -101.56)" color="#000" fill="url(#p)"/>
                                <path
                                    d="M611.95 489.09c0 2.021-1.67 3.66-3.729 3.66s-3.729-1.639-3.729-3.66 1.67-3.66 3.73-3.66 3.728 1.639 3.728 3.66z"
                                    transform="matrix(1 0 0 1.0667 -227.72 -200.51)" color="#000" fill="url(#q)"/>
                                <g stroke="#000" stroke-linecap="round" fill="none">
                                    <path d="m379.268 319.15 2.513 3.916M382.418 319.76l-3.792 2.695"/>
                                </g>
                                <path d="m380.41 321.82.879 1.38M379.75 318.95l.879 1.38" stroke="#e7e7e7"
                                      stroke-linecap="round" stroke-width=".05" fill="none"/>
                                <path
                                    d="M768.73 511.93c0 78.663-63.769 142.43-142.43 142.43-78.663 0-142.43-63.769-142.43-142.43 0-78.663 63.769-142.43 142.43-142.43 78.663 0 142.43 63.769 142.43 142.43z"
                                    transform="translate(217.96 71.337) scale(.26073)" stroke-linejoin="round"
                                    stroke-width="7.671" color="#000" stroke="url(#u)" stroke-linecap="round"
                                    fill="url(#h)"/>
                                <path
                                    d="M402.15 204.32c0 10.247-8.307 18.555-18.555 18.555-10.247 0-18.555-8.307-18.555-18.555 0-10.247 8.307-18.555 18.555-18.555 10.247 0 18.555 8.307 18.555 18.555z"
                                    transform="translate(-2.344 .496)" stroke-linejoin="round" color="#000"
                                    stroke="url(#v)" stroke-linecap="round" fill="url(#w)"/>
                                <rect rx="25" ry="25" height="55.243" width="317.09" y="639.15" x="222.08"
                                      opacity=".703" stroke-linejoin="round" color="#000" filter="url(#x)"
                                      stroke="#4a4a4a" stroke-linecap="round" stroke-width="3" fill="url(#y)"/>
                                <g>
                                    <path
                                        d="M612.94 491.62c0 3.117-2.382 5.644-5.32 5.644s-5.322-2.527-5.322-5.644 2.383-5.644 5.321-5.644 5.321 2.527 5.321 5.644z"
                                        transform="matrix(.83482 0 0 .86159 -84.705 -179.125)" color="#000"
                                        fill="url(#z)"/>
                                    <path
                                        d="M611.95 489.09c0 2.021-1.67 3.66-3.729 3.66s-3.729-1.639-3.729-3.66 1.67-3.66 3.73-3.66 3.728 1.639 3.728 3.66z"
                                        transform="matrix(1 0 0 1.0667 -185.682 -278.075)" color="#000" fill="url(#s)"/>
                                    <g stroke="#000" stroke-linecap="round" fill="none">
                                        <path d="m421.31 241.585 2.513 3.915M424.46 242.195l-3.792 2.695"/>
                                    </g>
                                </g>
                                <g>
                                    <path
                                        d="M612.94 491.62c0 3.117-2.382 5.644-5.32 5.644s-5.322-2.527-5.322-5.644 2.383-5.644 5.321-5.644 5.321 2.527 5.321 5.644z"
                                        transform="matrix(.83482 0 0 .86159 -166.547 -179.127)" color="#000"
                                        fill="url(#z)"/>
                                    <path
                                        d="M611.95 489.09c0 2.021-1.67 3.66-3.729 3.66s-3.729-1.639-3.729-3.66 1.67-3.66 3.73-3.66 3.728 1.639 3.728 3.66z"
                                        transform="matrix(1 0 0 1.0667 -267.527 -278.077)" color="#000" fill="url(#s)"/>
                                    <g stroke="#000" stroke-linecap="round" fill="none">
                                        <path d="m339.445 241.583 2.513 3.915M342.595 242.193l-3.792 2.695"/>
                                    </g>
                                </g>
                                <g>
                                    <path
                                        d="M612.94 491.62c0 3.117-2.382 5.644-5.32 5.644s-5.322-2.527-5.322-5.644 2.383-5.644 5.321-5.644 5.321 2.527 5.321 5.644z"
                                        transform="matrix(.83482 0 0 .86159 -84.705 -259.125)" color="#000"
                                        fill="url(#z)"/>
                                    <path
                                        d="M611.95 489.09c0 2.021-1.67 3.66-3.729 3.66s-3.729-1.639-3.729-3.66 1.67-3.66 3.73-3.66 3.728 1.639 3.728 3.66z"
                                        transform="matrix(1 0 0 1.0667 -185.682 -358.075)" color="#000" fill="url(#s)"/>
                                    <g stroke="#000" stroke-linecap="round" fill="none">
                                        <path d="m421.31 161.585 2.513 3.916M424.46 162.195l-3.792 2.695"/>
                                    </g>
                                </g>
                                <g>
                                    <path
                                        d="M612.94 491.62c0 3.117-2.382 5.644-5.32 5.644s-5.322-2.527-5.322-5.644 2.383-5.644 5.321-5.644 5.321 2.527 5.321 5.644z"
                                        transform="matrix(.83482 0 0 .86159 -166.547 -259.127)" color="#000"
                                        fill="url(#z)"/>
                                    <path
                                        d="M611.95 489.09c0 2.021-1.67 3.66-3.729 3.66s-3.729-1.639-3.729-3.66 1.67-3.66 3.73-3.66 3.728 1.639 3.728 3.66z"
                                        transform="matrix(1 0 0 1.0667 -267.527 -358.077)" color="#000" fill="url(#s)"/>
                                    <g stroke="#000" stroke-linecap="round" fill="none">
                                        <path d="m339.445 161.583 2.513 3.916M342.595 162.193l-3.792 2.695"/>
                                    </g>
                                </g>
                                <g transform="translate(-3.315 3.315)" color="#000" fill="url(#B)">
                                    <path d="M73.357 521.38a4.304 4.304 0 1 1-8.608 0 4.304 4.304 0 0 1 8.608 0z"
                                          transform="matrix(1.3083 0 0 1.296 151.16 -74.328)" opacity=".789"
                                          filter="url(#A)"/>
                                    <path d="M246.737 601.698a4.304 4.304 0 1 1-8.608 0 4.304 4.304 0 0 1 8.608 0z"
                                          opacity=".563" stroke-width=".5" stroke="#000"/>
                                    <path d="M246.737 601.503a4.304 4.304 0 1 1-8.608 0 4.304 4.304 0 0 1 8.608 0z"
                                          opacity=".563"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div class="w-full">
                        <!--                        <p class="text-sm leading-tight" v-if="file.id">ID: {{ file.id }}</p>-->
                        <div class="shadow w-full bg-grey-light" v-if="!file.success">
                            <div class="text-xs leading-none py-1 text-center text-white bg-yellow-400"
                                 :style="`width: ${file.progress}%`">
                                {{ file.progress }}%
                            </div>
                        </div>
                        <div v-else class="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 507.2 507.2">
                                <circle cx="253.6" cy="253.6" r="253.6" fill="#32ba7c"/>
                                <path fill="#0aa06e"
                                      d="M188.8 368l130.4 130.4c108-28.8 188-127.2 188-244.8v-7.2L404.8 152l-216 216z"/>
                                <g fill="#fff">
                                    <path
                                        d="M260 310.4c11.2 11.2 11.2 30.4 0 41.6l-23.2 23.2c-11.2 11.2-30.4 11.2-41.6 0L93.6 272.8c-11.2-11.2-11.2-30.4 0-41.6l23.2-23.2c11.2-11.2 30.4-11.2 41.6 0L260 310.4z"/>
                                    <path
                                        d="M348.8 133.6c11.2-11.2 30.4-11.2 41.6 0l23.2 23.2c11.2 11.2 11.2 30.4 0 41.6l-176 175.2c-11.2 11.2-30.4 11.2-41.6 0l-23.2-23.2c-11.2-11.2-11.2-30.4 0-41.6l176-175.2z"/>
                                </g>
                            </svg>
                            <span class="text-xs text-gray-400 pl-2">Файл успешно загружен</span>
                        </div>
                        <!--                        <p class="text-sm leading-tight">{{ file.type }}</p>-->
                        <p class="mt-1">{{ file.name }}</p>
                        <!--                        <div class="mt-1 relative rounded-md shadow-sm">-->
                        <!--                            <input type="text" v-model="file.name"-->
                        <!--                                   class="focus:ring-indigo-500 focus:border-indigo-500 text-sm py-1 block w-full px-2 sm:text-sm border-gray-300 rounded-md"-->
                        <!--                                   placeholder="">-->
                        <!--                        </div>-->
                    </div>
                    <button type="button"
                            class="absolute top-2 right-2 block ml-auto w-5 h-5 bg-gray-200 rounded flex flex-col items-center justify-center hover:bg-gray-300"
                            @click.prevent="remove(file.file_id)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-2 h-2 fill-current"
                             viewBox="0 0 512.001 512.001">
                            <path
                                d="M294.111 256.001L504.109 46.003c10.523-10.524 10.523-27.586 0-38.109-10.524-10.524-27.587-10.524-38.11 0L256 217.892 46.002 7.894C35.478-2.63 18.416-2.63 7.893 7.894s-10.524 27.586 0 38.109l209.998 209.998L7.893 465.999c-10.524 10.524-10.524 27.586 0 38.109 10.524 10.524 27.586 10.523 38.109 0L256 294.11l209.997 209.998c10.524 10.524 27.587 10.523 38.11 0 10.523-10.524 10.523-27.586 0-38.109L294.111 256.001z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <file-upload
            ref="upload"
            v-show="!files.length"
            v-model="files"
            :multiple="multiple"
            :data="{private}"
            :headers="{'X-XSRF-TOKEN': token}"
            class="block rounded bg-gray-200 text-sm px-4 py-2 hover:bg-gray-300 cursor-pointer"
            post-action="/api/files/store"
            @input-file="inputFile"
        >
            {{ buttonText }}
        </file-upload>
        <!--        <div>-->
        <!--            <button class="block rounded bg-green-400 text-sm px-4 py-2 mt-1 hover:bg-green-500 cursor-pointer"-->
        <!--                    v-if="!$refs.upload || !$refs.upload.active && files.length"-->
        <!--                    @click.prevent="$refs.upload.active = true" type="button">Загрузить-->
        <!--            </button>-->
        <!--            <button class="block rounded bg-yellow-300 text-sm px-4 py-2 mt-1 hover:bg-yellow-400 cursor-pointer"-->
        <!--                    v-if="$refs.upload && $refs.upload.active"-->
        <!--                    @click.prevent="$refs.upload.active = false" type="button">Остановить загрузку-->
        <!--            </button>-->
        <!--        </div>-->
    </div>
</template>

<script>
import axios from "axios";
import VueUploadComponent from 'vue-upload-component'

export default {
    name: 'AllFilesUploader',

    props: {
        field: {
            required: false
        },
        multiple: {
            required: false,
            default: false
        },
        error: {
            required: false
        },
        required: {
            required: false,
            default: false
        },
        buttonText: {
            required: false,
            default: 'Выбрать файл'
        },
        label: {
            required: false,
            default: 'Выбрать файл'
        },
        name: {
            required: false,
            default: 'path'
        },
        video: {
            required: false
        },
        model: {
            required: false,
            type: String
        },
        modelId: {
            required: false
        },
        private: {
            default: false
        }
    },

    data: function () {
        return {
            files: [],
        }
    },

    computed: {
        token() {
            return document.cookie.split(';').find(item => _.startsWith(_.trim(item), 'XSRF-TOKEN')).substr(11).replace('%3D', '=')
        }
    },

    components: {
        FileUpload: VueUploadComponent,
    },
    methods: {
        inputFile: function (newFile, oldFile) {
            // Automatic upload
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$refs.upload.active) {
                    this.$refs.upload.active = true
                }
            }
            // Successfully uploaded
            if (newFile.response.success) {
                this.files.map(file => {
                    if (file.id === newFile.id) {
                        file.path = newFile.response.file.url
                        file.name = newFile.response.file.original_name
                        file.file_id = newFile.response.file.id
                    }
                    return file
                })
            }
        },

        inputFilter: function (newFile, oldFile, prevent) {
            newFile.blob = ''
            let URL = window.URL || window.webkitURL
            if (URL && URL.createObjectURL) {
                newFile.blob = URL.createObjectURL(newFile.file)
            }
        },

        remove(id) {
            axios.post('/api/files/delete', {
                id
            }).then(({data}) => {
                if (data.success) {
                    this.files.splice(0, 1)
                }
            }).catch(e => {
                console.log('e', e)
            })
        },

        tryParseJson(json_string) {
            try {
                if (!json_string) {
                    return null
                }
                return JSON.parse(json_string)
            } catch (e) {
                return null;
            }
        }
    },

    mounted() {
        const media = this.tryParseJson(this.$attrs.value)
        if (media) {
            this.files.push({
                id: media.id,
                file_id: media.id,
                type: media.mime,
                name: media.original_name,
                success: true,
                path: media.url
            })
        }
    }
}
</script>
