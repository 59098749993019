<template>
    <div>
        <div class="border-b border-gray-200 pb-3 mt-3">
            <label class="block text-sm font-medium text-gray-700">Название<span
                class="text-red-400">*</span>:</label>
            <div class="mt-1 relative rounded-md shadow-sm">
                <input type="text" name="title" v-model="title"
                       class="focus:ring-indigo-500 focus:border-indigo-500 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                       :class="!!error ? 'border-red-400' : ''"
                       placeholder="">
            </div>

            <p class="text-red-500 text-sm mt-1 border-gray-300" v-if="error">{{ error }}</p>
        </div>
<!--        <div class="border-b border-gray-200 pb-3 mt-3">-->
<!--            <label class="block text-sm font-medium text-gray-700">Slug:</label>-->
<!--            <div class="mt-1 relative rounded-md shadow-sm">-->
<!--                <input type="text" name="slug" v-model="slug"-->
<!--                       class="focus:ring-indigo-500 focus:border-indigo-500 block w-full px-2 sm:text-sm border-gray-300 rounded-md"-->
<!--                       :class="error ? 'border-red-400' : ''"-->
<!--                       placeholder="">-->
<!--            </div>-->

<!--            <p class="text-red-500 text-sm mt-1" v-if="error">$message </p>-->
<!--        </div>-->
    </div>
</template>

<script>
export default {
    name: "TitleAndSlug",

    props: {
        error: {
            required: false
        },
        titleProp: {
            required: false
        },
        slugProp: {
            required: false
        }
    },

    watch: {
      title(value) {
          this.slug = value
      }
    },

    data() {
        return {
            title: this.titleProp ?? '',
            slug: this.slugProp ?? ''
        }
    }
}
</script>
