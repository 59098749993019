<template>
    <div>
        <p class="block text-sm font-medium text-gray-700">{{ title }}<span v-if="required"
                                                                            class="text-red-400">*</span></p>
        <div class="flex items-center mt-1">
            <label :for="name" v-if="src"
                   class="block text-sm font-medium w-24 h-24 text-gray-700 hover:opacity-60 cursor-pointer mr-4">
                <img :src="src" class="block h-full object-cover rounded shadow" alt="">
            </label>
            <button class="block rounded text-sm bg-indigo-100 px-4 py-2 hover:bg-indigo-200" v-if="file"
                    @click.prevent="onCancel">Отменить
            </button>
            <button v-else
                    class="block rounded bg-gray-200 text-sm px-4 py-2 hover:bg-gray-300"
                    @click.prevent="$refs[name].click()">{{ src ? 'Изменить картинку' : 'Выбрать картинку' }}
            </button>
        </div>
        <p class="block text-red-500 text-sm mt-1" v-if="error">{{ error }}</p>
        <input type="file" :id="name" class="hidden" :name="name" :ref="name" accept="image/jpeg, image/png, image/svg"
               @change="onChange"/>
        <input type="text" :name="name" class="hidden" v-if="filename" v-model="filename">
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'ImageUploader',

    props: {
        error: {
            required: false
        },
        required: {
            required: false,
            default: false
        },
        labelTitle: {
            default: ''
        },
        autoUpload: {
            required: false,
            default: false
        },
        field: {
            required: false
        },
        imagePath: {
            type: String,
            required: false
        }
    },

    data() {
        return {
            file: null,
            value: this.$attrs.value ?? null,
            filename: this.field
        }
    },

    computed: {
        src() {
            if (this.file) {
                return this.file
            }
            if (this.imagePath) {
                return this.imagePath
            }
            if (this.field && this.field.value) {
                return `/storage/images/${this.field.value}`
            }
            if (!this.$attrs.value) {
                return null
            }
            return `/storage/${this.$attrs.value}`

        },
        name() {
            return this.$attrs.name ?? 'image'
        },
        title() {
            if (this.labelTitle) {
                return this.labelTitle
            }
            return this.field?.title ?? 'Картинка'
        },
    },

    methods: {
        onChange(event) {
            if (event.target?.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    this.file = e.target.result;
                    if (this.autoUpload) {
                        this.upload(event.target?.files[0])
                    }
                }
                reader.readAsDataURL(event.target?.files[0]);
                this.$emit('emitFile', event.target?.files[0])
            }
        },

        upload(file) {
            let formData = new FormData();
            formData.append('file', file);
            axios.post('/api/upload-file',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(res => {
                console.log('res.data', res.data)
                this.filename = res.data.path

                let field = this.field
                if (field) {
                    field.value = res.data.path
                    this.$emit('updateField', field)
                }
            })
                .catch(function (e) {
                    console.log('FAILURE!!', e);
                });
        },

        onCancel() {
            this.file = null
            this.$refs[this.name].value = null
        }
    }
}
</script>
