export const FIELDS_METHODS = {
    props: {
        field: {
            required: false
        }
    },

    data() {
        return {
            value: this.field
        }
    },
}
