<template>
    <div class="block min-w-full mt-4 bg-white rounded-md shadow">
        <div class="bg-gray-200 px-3 py-2 flex">
            <h4>{{ block.title }}</h4>
            <div class="ml-3 cursor-pointer">
                <input type="checkbox" class="cursor-pointer" v-model="block.is_active" :id="`show_block_${block.id}`">
                <label class="text-sm cursor-pointer" :for="`show_block_${block.id}`">Показывать блок</label>
            </div>
        </div>
        <div class="p-3">
            <div class="border-b border-gray-100 mt-3 pb-3" v-for="field in sortedFields">
                <component :is="field.type" :field.sync="field" :auto-upload="true"/>
            </div>
            <div class="flex mt-3">
                <button class="block rounded bg-green-200 px-4 py-2 hover:bg-green-300"
                        @click="onSubmit">Сохранить
                </button>
            </div>
        </div>
        <notifications></notifications>
    </div>
</template>

<script>
import StringField from './fields/StringField'
import TextField from "./fields/TextField";
import ImageUploader from "./fields/ImageUploader";
import ButtonsField from "./fields/ButtonsField";
import axios from "axios";
import DigitField from "./fields/DigitField";
import FeaturesField from "./fields/FeaturesField";
import CardField from "./fields/CardField";
import EditorField from "./fields/EditorField";
import EducationField from "./fields/EducationField";
import SettingsField from "./fields/SettingsField";

export default {
    name: "block",

    props: {
        block: {
            required: false
        }
    },

    components: {
        'stringfield': StringField,
        'textfield': TextField,
        'imagefield': ImageUploader,
        'buttonsfield': ButtonsField,
        'digitfield': DigitField,
        'featuresfield': FeaturesField,
        'cardField': CardField,
        'editorfield': EditorField,
        'educationfield': EducationField,
        'settingsField': SettingsField
    },

    computed: {
        sortedFields() {
            return _.sortBy(this.block.fields, item => item.order)
        }
    },

    methods: {
        async onSubmit() {
            try {
                const {data} = await axios.post(`/api/blocks/${this.block.id}/update`, {
                    block: this.block
                })
                this.$notify({
                    type: 'success',
                    title: 'Получилось!',
                    text: 'Блок изменен'
                });
                // console.log('data', data)
            } catch (e) {
                this.$notify({
                    type: 'error',
                    title: 'Упс! Что-то пошло не так',
                    text: e
                });
            }
        },
    }
}
</script>
