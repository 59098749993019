<template>
<div>
    <div class="ml-3 cursor-pointer" v-for="setting in settings">
        <input type="checkbox" class="cursor-pointer" v-model="value.value" :value="setting.id" :id="`show_block_${setting.id}`">
        <label class="text-sm cursor-pointer" :for="`show_block_${setting.id}`">{{setting.title}}</label>
    </div>
</div>
</template>

<script>
import {FIELDS_METHODS} from "./mixins";
import axios from "axios";

export default {
    name: "SettingsField",

    mixins: [FIELDS_METHODS],

    data() {
      return {
          settings: []
      }
    },

    watch: {
        value: {
            deep: true,
            handler: function (val) {
                this.$emit('updateField', val)
            }
        }
    },

    async mounted() {
        const { data } = await axios.get('/api/settings', {
            params: {
                group_slug: 'contacts'
            }
        });
        this.settings = data
    }
}
</script>
