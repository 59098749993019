<template>
    <div>
        <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr>
                <th class="p-4 pr-0 relative text-left">
                    <button class="inline-block rounded bg-gray-200 px-3 py-1 hover:bg-gray-300" @click="toggleSelected"
                            v-if="feedbacks.length">
                        <span>{{ selected.length === feedbacks.length ? 'Unselect' : 'Select All' }}</span>
                    </button>
                    <transition name="fade">
                        <div class="absolute left-full top-0 bg-white top-2 rounded-sm shadow p-3"
                             v-if="selected.length">
                            <button
                                class="block p-2 bg-red-400 text-xs whitespace-nowrap font-bold text-white rounded-sm shadow-sm hover:bg-red-500"
                                @click="massDelete">Удалить без подтверждения
                            </button>
                        </div>
                    </transition>
                </th>
                <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Фотка
                </th>
                <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Имя
                </th>
                <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Отзыв
                </th>
                <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Статус
                </th>
                <th scope="col" class="relative px-6 py-3 text-right">
                    <a :href="$attrs.create_url"
                       class="inline-block rounded bg-green-200 px-4 py-1 hover:bg-green-300">
                        Добавить
                    </a>
                </th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">

            <tr v-for="feedback in feedbacks" :key="feedback.id">
                <td class="p-4 pr-0">
                    <div class="cursor-pointer">
                        <input type="checkbox" v-model="selected" :value="feedback.id" name="selected">
                        <!--                        <label class="text-sm cursor-pointer" :for="`show_block_${block.id}`">Показывать блок</label>-->
                    </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ">
                    <img class="w-12 h-12 object-cover" :src="feedback.image" alt=""
                         v-if="feedback.image"/>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ">
                    {{ feedback.name }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ feedback.text.substring(0, 40) }}...
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ">
                    <moderate-feedback :moderated="feedback.moderated " :id="feedback.id"
                                       :remove-route="`/api/feedbacks/${feedback.id}/remove`"></moderate-feedback>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <a :href="`/dashboard/feedbacks/${feedback.id}/edit`"
                       class="text-indigo-600 hover:text-indigo-900">Изменить</a>
                    <span class="px-2 text-gray-200">|</span>
                    <modal-remove-confirmation-link
                        :remove-route="`/api/feedbacks/${feedback.id}/remove`"
                        :success-route="$attrs.success_url"></modal-remove-confirmation-link>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import ModerateFeedback from "./ModerateFeedback";
import ModalRemoveConfirmationLink from "../common/remove/ModalRemoveConfirmationLink";
import axios from "axios";

export default {
    name: "FeedbacksTable",

    components: {
        ModerateFeedback,
        ModalRemoveConfirmationLink
    },

    data() {
        return {
            selected: []
        }
    },

    computed: {
        feedbacks() {
            if (!this.$attrs.feedbacks) {
                return []
            }
            try {
                return JSON.parse(this.$attrs.feedbacks).data
            } catch (e) {
                return []
            }
        }
    },

    methods: {
        toggleSelected() {
            if (this.selected.length === this.feedbacks.length) {
                this.selected = []
            } else {
                this.selected = this.feedbacks.map(item => item.id)
            }
        },
        massDelete() {
            axios.post('/api/feedbacks/mass-delete', {
                'feedback_ids': this.selected
            }).then(({data}) => {
                if (data.success) {
                    document.location.reload()
                }
            }).catch(e => {
                console.log('e', e)
            })
        }
    }
}
</script>
