<template>
    <div>
        <div class="flex items-center">
            <label :for="field.name" class="block text-sm font-medium text-gray-700">{{ field.title }}</label>
            <button type="button" class=" ml-3 inline-block px-2 py-1 bg-green-400 text-white rounded text-xs"
                    @click="addItem">Добавить
            </button>
        </div>
        <p class="mt-2 text-gray-800 text-sm" v-if="!value.value.length">Пока ничего нет...</p>
        <div class="grid grid-cols-3 gap-4 mt-4" v-else>
            <div v-for="(item, i) in value.value" :key="i">
                <div class="rounded shadow p-2 bg-gray-50 relative">
                    <button type="button"
                            class="absolute right-2 top-2 block ml-auto w-5 h-5 bg-gray-200 rounded flex flex-col items-center justify-center hover:bg-gray-300"
                            @click.prevent="removeItem(i)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-2 h-2 fill-current"
                             viewBox="0 0 512.001 512.001">
                            <path
                                d="M294.111 256.001L504.109 46.003c10.523-10.524 10.523-27.586 0-38.109-10.524-10.524-27.587-10.524-38.11 0L256 217.892 46.002 7.894C35.478-2.63 18.416-2.63 7.893 7.894s-10.524 27.586 0 38.109l209.998 209.998L7.893 465.999c-10.524 10.524-10.524 27.586 0 38.109 10.524 10.524 27.586 10.523 38.109 0L256 294.11l209.997 209.998c10.524 10.524 27.587 10.523 38.11 0 10.523-10.524 10.523-27.586 0-38.109L294.111 256.001z"/>
                        </svg>
                    </button>
                    <div class="relative rounded-md shadow-sm mt-3">
                        <label class="block text-sm font-medium text-gray-700">Название<span class="text-red-500">*</span></label>
                        <input type="text" v-model="item.title"
                               class="focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                               placeholder="">
                    </div>
                    <div class="relative rounded-md shadow-sm mt-3">
                        <label class="block text-sm font-medium text-gray-700">Описание</label>
                        <textarea v-model="item.description"
                                  class="focus:ring-indigo-500 resize-none focus:border-indigo-500 mt-1 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                                  placeholder=""></textarea>
                    </div>
                    <image-uploader :field="item" class="mt-3" label-title="Изображение" :auto-upload="true"></image-uploader>
                    <div class="mt-3">
                        <label class="block text-sm font-medium text-gray-700">Период обучения</label>
                        <div class="flex items-center">
                            <div class="relative rounded-md shadow-sm mr-1">
                                <input type="text" v-model="item.date_from"
                                       class="focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                                       placeholder="">
                            </div>
                            <span>-</span>
                            <div class="relative rounded-md shadow-sm ml-1">
                                <input type="text" v-model="item.date_to"
                                       class="focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                                       placeholder="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {FIELDS_METHODS} from "./mixins";
import ImageUploader from "./ImageUploader";

export default {
    name: "EducationField",

    mixins: [FIELDS_METHODS],

    components: {
        ImageUploader
    },

    watch: {
        value: {
            deep: true,
            handler: function (val) {
                this.$emit('updateField', val)
            }
        }
    },

    computed: {
      canAdd() {
          return !this.value.value.length || !!_.last(this.value.value)['title']
      }
    },

    methods: {
        addItem() {
            if(this.canAdd) {
                this.value.value.push({
                    date_from: '',
                    date_to: '',
                    description: '',
                    image: '',
                    title: ''
                })
            }
        },
        removeItem(i) {
            this.value.value.splice(i, 1)
        },
    }
}
</script>
