<template>
<div class="p-5 rounded-lg">
    <h3 class="font-semibold text-lg text-center">Подтверждаем удаление?</h3>
    <div class="flex justify-between mt-3">
        <button type="button" class="block px-4 py-2 bg-red-200 hover:bg-red-300 w-1/2 mr-2" @click.prevent="onSubmit">Удалить</button>
        <button type="button" class="block px-4 py-2 bg-gray-200 hover:bg-gray-300 w-1/2 ml-2" @click.prevent="$emit('close')">Отменить</button>
    </div>
</div>
</template>

<script>
export default {
    name: "ModalRemoveConfirmationDialog",

    methods: {
        onSubmit() {
            this.$parent.$emit('onSubmit')
            this.$emit('close')
        }
    }
}
</script>
