<template>
    <div>
<!--        <datepicker :name="name"-->
<!--                    format="dd.MM.yyyy"-->
<!--                    v-model="date"-->
<!--                    input-class="w-full rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full px-2 sm:text-sm border-gray-300"-->
<!--                    :monday-first="true"-->
<!--                    ></datepicker>-->
        <date-picker v-model="date"
                     input-class="w-full rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full px-2 sm:text-sm border-gray-300"
                     format="DD.MM.YYYY HH:mm"
                     :input-attr="{ name: 'published_at' }"
                     type="datetime"></date-picker>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru';

export default {
    name: "DateInput",

    props: {
        dateProp: {
            required: false
        },
        name: {
            required: false
        }
    },

    data() {
        return {
            date: this.dateProp ? new Date(this.dateProp) : new Date()
        }
    },

    components: {
        DatePicker
    }
}
</script>
