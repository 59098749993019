<template>
    <div>
        <label :for="field.name" class="block text-sm font-medium text-gray-700">{{ field.title }}</label>
        <div class="flex mt-2 flex-wrap">
            <div class="w-1/3" v-for="(item, i) in value.value" :key="i">
                <div class="rounded shadow p-2 bg-gray-50 mr-4">
                    <div class="relative rounded-md shadow-sm">
                        <label class="block text-sm font-medium text-gray-700">Иконка</label>
                        <textarea v-model="item.icon"
                                  class="h-52 resize-none focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                                  placeholder=""></textarea>
                    </div>
                    <div class="relative rounded-md shadow-sm mt-3">
                        <label class="block text-sm font-medium text-gray-700">Заголовок</label>
                        <input type="text" v-model="item.title"
                               class="focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                               placeholder="">
                    </div>
                    <div class="relative rounded-md shadow-sm mt-3">
                        <label class="block text-sm font-medium text-gray-700">Описание</label>
                        <textarea v-model="item.description"
                                  class="focus:ring-indigo-500 resize-none focus:border-indigo-500 mt-1 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                                  placeholder=""></textarea>
                    </div>
                    <div class="relative rounded-md shadow-sm mt-3">
                        <label class="block text-sm font-medium text-gray-700">Текст на кнопке <small class="text-gray-400">Если пустой - кнопки не будет</small></label>
                        <input type="text" v-model="item.button_text"
                               class="focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                               placeholder="">
                    </div>
                    <div class="relative rounded-md shadow-sm mt-3">
                        <label class="block text-sm font-medium text-gray-700">URL <small class="text-gray-400">Если пустой - не будет ссылкой</small></label>
                        <input type="text" v-model="item.route"
                               class="focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                               placeholder="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {FIELDS_METHODS} from "./mixins";

export default {
    name: "FeaturesField",

    mixins: [FIELDS_METHODS],

    watch: {
        value: {
            deep: true,
            handler: function (val) {
                this.$emit('updateField', val)
            }
        }
    }
}
</script>
