<template>
    <div class="border-b border-gray-200 pb-3 mt-3 vue-quill-container">
        <label class="block text-sm font-medium text-gray-700">{{ label }}<span v-if="required"
            class="text-red-400">*</span>:</label>
        <quill-editor class="mt-1" :content="content"
                      ref="myQuillEditor"
                      :options="editorOptions"
                      @change="onEditorChange($event)"></quill-editor>
        <input type="text" class="hidden" :name="name" v-model="content">
        <form id="upload" class="hidden" enctype="multipart/form-data" method="post">
            <input type="file" name="file" id="selectImg" accept="image/gif, image/jpeg, image/png"
                   @change="inputChangeImg"
                   multiple/>
            <input type="button" value="Submit" @click="uploadPic"/>
        </form>
        <p class="text-red-500 text-sm mt-1 border-gray-300" v-if="error">{{ error }}</p>
    </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import {quillEditor} from 'vue-quill-editor'
import axios from "axios";

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{'header': 1}, {'header': 2}],               // custom button values
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
    [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
    [{'direction': 'rtl'}],                         // text direction

    [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
    [{'header': [1, 2, 3, 4, 5, 6, false]}],

    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    [{'font': []}],
    [{'align': []}],
    ['link', 'image', 'video'],
    ['clean']                                         // remove formatting button
]


export default {
    name: "Editor",

    components: {
        quillEditor
    },

    props: {
        error: {
            required: false
        },
        required: {
            required: false,
            default: false
        },
        contentProp: {
            required: false
        },
        name: {
            required: false,
            default: 'content'
        },
        label: {
            required: false,
            default: 'Контент'
        }
    },

    data() {
        return {
            content: this.contentProp,
            editorOptions: {
                placeholder: 'Контент поста здесь...',
                bounds: '.vue-quill-container',
                modules: {
                    toolbar: {
                        container: toolbarOptions,
                        handlers: {
                            'image': function (value) {
                                if (value) {
                                    // alert ('custom picture')
                                    document.getElementById("selectImg").click(); //call select image
                                } else {
                                    this.quill.format('image', false);
                                }
                            }
                        }
                    },
                }
            }
        }
    },

    methods: {
        onEditorChange({quill, html, text}) {
            // console.log('editor change!', quill, html, text)
            this.content = html
            this.$emit('update:content-prop', this.content)
        },
        selectImg() { //Select image
            document.getElementById("selectImg").click();
        },
        inputChangeImg: function () { // input operation when selecting a picture
            let that = this;
            let input = document.getElementById('selectImg');
            if (input.files && input.files[0]) {
                let item = input.files[0];
                let reader = new FileReader();
                reader.onload = function (e) {
                    that.uploadPic().then(res => { //Upload images to the server
                        console.log(res);
                        that.handleSuccess(res)
                    }).catch(err => {
                        console.log(err);
                    });
                };
                reader.readAsDataURL(item);
            }
        },
        handleSuccess(res) {
            let quill = this.$refs.myQuillEditor.quill
            let length = quill.getSelection().index; // Get the cursor position
            quill.insertEmbed(length, 'image', `/storage/images/${res.data.path}`); // Insert image res.url for the image address returned by the server
            quill.setSelection(length + 1); // adjust the cursor to the end
        },
        uploadPic: function () { //Submit image
            let form = document.getElementById('upload'),
                formData = new FormData(form);
            return axios.post('/api/upload-file', formData)


        }
    }
}
</script>
