import Vue from 'vue'
import Block from './components/Block'
import ModalRemoveConfirmationButton from './components/common/remove/ModalRemoveConfirmationButton'
import ModalRemoveConfirmationLink from "./components/common/remove/ModalRemoveConfirmationLink";
import VModal from 'vue-js-modal'
import ImageUploader from './components/fields/ImageUploader'
import ModerateFeedback from './components/feedbacks/ModerateFeedback'
import Editor from './components/common/Editor'

import _ from 'lodash'
import TitleAndSlug from './components/common/TitleAndSlug'
import DateInput from './components/common/DateInput'
import Materials from './components/Materials'
import PasswordInput from './components/common/PasswordInput'
import FileUploader from './components/FileUploader'
import AllFilesUploader from './components/fields/AllFilesUploader'
import VideoPlayer from './components/VideoPlayer'
import MobileMenu from './components/MobileMenu'
import DropdownItem from "./components/DropdownItem";
import LogoutLink from "./components/LogoutLink";
import VideoSelect from "./components/VideoSelect";
import Notifications from 'vue-notification'
import Tabs from "./components/common/Tabs";
import SettingsComponent from "./components/SettingsComponent";
import ProfileInput from "./components/profile/ProfileInput"
import Material from "./components/Material";
import FeedbacksTable from "./components/feedbacks/FeedbacksTable";
import SimpleDropzone from "./components/SimpleDropzone"


Vue.use(VModal)
Vue.use(Notifications)

const app = new Vue({
    el: '#app',
    components: {
        Block,
        ModalRemoveConfirmationButton,
        ModalRemoveConfirmationLink,
        ImageUploader,
        ModerateFeedback,
        TitleAndSlug,
        Editor,
        DateInput,
        Materials,
        PasswordInput,
        FileUploader,
        AllFilesUploader,
        VideoPlayer,
        MobileMenu,
        DropdownItem,
        LogoutLink,
        VideoSelect,
        Tabs,
        SettingsComponent,
        ProfileInput,
        Material,
        FeedbacksTable,
        SimpleDropzone
    }
})
