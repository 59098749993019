<template>
    <a href="#" @click.prevent="logout">
        <slot></slot>
    </a>
</template>

<script>
import axios from "axios";

export default {
    name: "LogoutLink",

    props: {
        logoutUrl: {
            type: String,
            required: true
        }
    },

    methods: {
        async logout() {
            const { data: { redirect } } = await axios.post(this.logoutUrl)
            window.location.href = redirect
        }
    }
}
</script>
