<template>
    <div class="p-5">
        <h3 class="font-semibold text-lg text-center">Смена статуса мнения - отзыва</h3>
        <div class="flex justify-between mt-3">
            <button type="button" class="block px-4 py-2 bg-green-200 hover:bg-green-300 w-1/3 mr-4"
                    @click.prevent="onApply" v-if="!moderated">Одобрить
            </button>
            <button type="button" class="block px-4 py-2 bg-red-200 hover:bg-red-300 w-1/3 mr-4"
                    @click.prevent="onDisable" v-else>Скрыть
            </button>
            <button type="button" class="block px-4 py-2 bg-red-200 hover:bg-red-300 w-1/3 mr-4"
                    @click.prevent="onDelete">Удалить
            </button>
            <button type="button" class="block px-4 py-2 bg-gray-200 hover:bg-gray-300 w-1/3"
                    @click.prevent="$emit('close')">Отменить
            </button>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ModalRemoveConfirmationDialog from "../common/remove/ModalRemoveConfirmationDialog";

export default {
    name: "ModalFeedbackStatusChange",

    props: {
        moderated: {
            required: false
        }
    },

    components: {
        ModalRemoveConfirmationDialog
    },

    methods: {
        onApply() {
            this.$parent.$emit('onSetStatus', true)
            this.$emit('close')
        },
        onDisable() {
            this.$parent.$emit('onSetStatus', false)
            this.$emit('close')
        },
        onDelete() {
            this.$modal.show(ModalRemoveConfirmationDialog,
                {
                    moderated: this.moderated
                },
                {
                    height: 'auto',
                    width: '400',
                    classes: 'md:ml-24'
                },
                {
                    'onSubmit': async () => {
                        this.$parent.$emit('onDelete')
                        this.$emit('close')
                    }
                })
        }
    }
}
</script>
