<template>
    <a href="#" class="inline text-red-400 hover:text-red-500"
            type="button"
            @click.prevent="showModalConfirmation">
        Удалить
    </a>
</template>

<script>
import {MODAL_REMOVE_METHODS} from "./mixins";

export default {
    name: "ModalRemoveConfirmationLink",

    mixins: [MODAL_REMOVE_METHODS],
}
</script>
