<template>
    <div class="relative">
        <div @mouseenter="isOpened = true">
            <slot name="target"></slot>
        </div>
        <div v-if="isOpened" @mouseleave="isOpened = false" class="absolute top-full right-0 w-full">
            <slot name="dropdown"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "DropdownItem",

    data() {
        return {
            isOpened: false
        }
    }
}
</script>

