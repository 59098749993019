<template>
    <div>
        <div class="flex items-center">
            <label class="block text-sm font-medium text-gray-700">{{ label }}</label>
            <button type="button" class=" ml-3 inline-block px-2 py-1 bg-green-400 text-white rounded text-xs"
                    @click.prevent="addMaterial">Добавить
            </button>
        </div>
        <div class="flex flex-wrap">
            <div class="w-1/3 lg:w-1/4 pr-4 mt-4" v-for="(item, i) in materials" :key="i">
                <div class="rounded shadow p-2 bg-gray-50">
                    <button type="button"
                            class="block ml-auto w-5 h-5 bg-gray-200 rounded flex flex-col items-center justify-center hover:bg-gray-300"
                            @click.prevent="removeMaterial(i)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-2 h-2 fill-current"
                             viewBox="0 0 512.001 512.001">
                            <path
                                d="M294.111 256.001L504.109 46.003c10.523-10.524 10.523-27.586 0-38.109-10.524-10.524-27.587-10.524-38.11 0L256 217.892 46.002 7.894C35.478-2.63 18.416-2.63 7.893 7.894s-10.524 27.586 0 38.109l209.998 209.998L7.893 465.999c-10.524 10.524-10.524 27.586 0 38.109 10.524 10.524 27.586 10.523 38.109 0L256 294.11l209.997 209.998c10.524 10.524 27.587 10.523 38.11 0 10.523-10.524 10.523-27.586 0-38.109L294.111 256.001z"/>
                        </svg>
                    </button>
                    <div class="relative rounded-md shadow-sm">
                        <label class="block text-sm font-medium text-gray-700">Название</label>
                        <input type="text" v-model="item.title"
                               class="focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                               placeholder="">
                    </div>
                    <div class="relative rounded-md shadow-sm mt-3">
                        <label class="block text-sm font-medium text-gray-700">Файл</label>
                        <file-uploader class="mt-1" :value.sync="item.link"></file-uploader>
                    </div>
                </div>
            </div>
        </div>
        <input type="text" class="hidden" :name="name" v-model="materialsString">
    </div>
</template>

<script>
import FileUploader from './FileUploader'

export default {
    name: "Materials",

    components: {
        FileUploader
    },

    props: {
        label: {
            type: String,
            default: 'Дополнительные материалы'
        },
        materialsProp: {
            required: false,
            type: String,
        },
        name: {
            required: false,
            default: 'materials'
        }
    },

    data() {
        return {
            materials: this.materialsProp ? JSON.parse(this.materialsProp) : [],
        }
    },

    computed: {
        materialsString() {
            return JSON.stringify(this.materials)
        }
    },

    methods: {
        addMaterial() {
            // console.log(this.materials.filter(item => !item.title).length)
            if (this.materials.length === 0 || !this.materials.filter(item => !item.title).length) {
                this.materials.push({
                    title: '',
                    link: ''
                })
            }
        },
        removeMaterial(i) {
            this.materials.splice(i, 1)
        },
        decode(value) {
            let array = null
            try {
                array = JSON.parse(value)
            } catch (e) {
                array = []
            }
            return array
        }
    }
}
</script>
