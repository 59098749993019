<template>
<div>
    <div class="border-b border-gray-200 pb-3 mt-3">
        <label class="block text-sm font-medium text-gray-700" v-if="label">{{ label }}</label>
        <div class="mt-1 relative rounded-md shadow-sm">
            <input :type="type" name="password" v-model="password"
                   class="focus:ring-indigo-500 focus:border-indigo-500 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                   :class="error ? 'border-red-400' : ''" />
        </div>
        <p class="text-red-500 text-sm mt-1" v-if="error">{{ error }}</p>
    </div>
    <div class="border-b border-gray-200 pb-3 mt-3" v-if="password">
        <label class="block text-sm font-medium text-gray-700" v-if="labelConfirmation">{{ labelConfirmation }}</label>
        <div class="mt-1 relative rounded-md shadow-sm">
            <input :type="type" name="password_confirmation" v-model="password_confirmation"
                   class="focus:ring-indigo-500 focus:border-indigo-500 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                   :class="error ? 'border-red-400' : ''" />
        </div>
        <p class="text-red-500 text-sm mt-1" v-if="error">{{ error }}</p>
    </div>
</div>
</template>

<script>

export default {
    name: "PasswordInput",

    props: {
        label: {
            type: String,
            default: 'Пароль'
        },
        labelConfirmation: {
            type: String,
            default: 'Подтверждение пароля'
        },
        name: {
            type: String,
            required: false,
            default: 'password'
        },
        value: {
            required: false
        },
        error: {
            required: false
        }
    },

    data() {
        return {
            password: '',
            password_confirmation: '',
            type: 'password'
        }
    }

}
</script>

<style scoped>

</style>
