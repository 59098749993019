<template>
    <div>
        <label :for="field.name" class="block text-sm font-medium text-gray-700">{{ field.title }}</label>
        <button class="bloc my-3 rounded-md bg-green-500 text-white py-1 px-4 font-bold text-sm" @click="addCard">Добавить</button>
        <div class="flex mt-1">
            <div class="rounded mr-2 shadow p-2 w-1/3 bg-gray-50 relative" v-for="(card, i) in value.value" :key="i">
                <button class="absolute top-0 right-0 left-auto bg-red-500 rounded p-2 font-bold text-sm">
                    <span class="w-4 h-4 z-10">
                        <svg class="w-2 h-2 fill-current" viewBox="0 0 365.696 365.696" xmlns="http://www.w3.org/2000/svg"><path d="M243.188 182.86L356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0"/></svg>
                    </span>
                </button>
                <div class="relative rounded-md shadow-sm">
                    <label class="block text-sm font-medium text-gray-700">Название карточки</label>
                    <input type="text" v-model="card.title"
                           class="focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                           placeholder="">
                </div>
                <div class="mt-3 relative rounded-md shadow-sm">
                    <label class="block text-sm font-medium text-gray-700">Текст карточки</label>
                    <textarea v-model="card.text"
                              class="focus:ring-indigo-500 resize-none focus:border-indigo-500 mt-1 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                              placeholder=""></textarea>
                </div>
                <div class="mt-3 relative rounded-md shadow-sm">
                    <label class="block text-sm font-medium text-gray-700">Иконка</label>
                    <textarea
                      class="focus:ring-indigo-500 resize-none focus:border-indigo-500 block w-full px-2 sm:text-sm border-gray-300 rounded-md"
                      placeholder="" v-model="card.icon"></textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {FIELDS_METHODS} from './mixins'

export default {
    name: "CardsField",

    mixins: [FIELDS_METHODS],

    watch: {
        value: {
            deep: true,
            handler: function (val) {
                this.$emit('updateField', val)
            }
        }
    },

    methods: {
        addCard() {
            this.value.value.push({
                icon: '',
                title: '',
                text: '',
            })
        }
    }
}
</script>
