<template>
    <button class="block rounded bg-red-200 px-4 py-2 hover:bg-red-300"
            type="button"
            @click.prevent="showModalConfirmation">
        Удалить
    </button>
</template>

<script>
import {MODAL_REMOVE_METHODS} from './mixins'

export default {
    name: "ModalRemoveConfirmation",

    mixins: [MODAL_REMOVE_METHODS],
}
</script>

