<template>
    <div>
        <div class="flex border-b border-gray-300">
            <!--            <button type="button" class="block px-6 pb-1 pt-2 text-gray-700 hover:text-gray-900" :class="`bg-${bgColor}`">Таб 1</button>-->
            <!--            <button type="button" class="block px-6 pb-1 pt-2 text-gray-900 active relative border-t border-l border-r border-gray-300 rounded-t-md" :class="`bg-${bgColor}`">Таб 2</button>-->
            <button type="button"
                    v-for="(tab, i) in tabs"
                    class="block px-6 pb-1 pt-2 rounded-t-md focus:outline-none border-t border-l border-r"
                    @click="active = i"
                    :class="i === active ? `text-gray-900 active relative border-gray-300 bg-${bgColor}` : `bg-${bgColor} border-${bgColor} text-gray-700 hover:text-gray-900 hover:bg-${bgHoverColor}`">
                {{ tab[titleKey] }}
            </button>
        </div>
        <div class="shadow-md border-b border-gray-300 border-r border-l p-4 rounded-b-md" :class="`bg-${bgColor}`">
            <div v-for="(tab, i) in tabs">
                <div v-if="active === i">
<!--                    <template v-if="!contentComponent">-->
<!--                        {{ tab[contentKey] }}-->
<!--                    </template>-->
                    <slot :tab="tab[contentKey]">
                        {{ tab[contentKey] }}
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Tabs",

    props: {
        bgColor: {
            default: 'white'
        },
        bgHoverColor: {
            default: 'gray-50'
        },
        tabs: {
            required: true,
            type: Array
        },
        titleKey: {
            type: String,
            default: 'title'
        },
        contentKey: {
            type: String,
            default: 'content'
        },
        contentComponent: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            active: 0
        }
    }
}
</script>

<style lang="scss">
.active {
    &:after {
        content: '';
        position: absolute;
        top: 100%;
        width: 100%;
        background-color: inherit;
        height: 1px;
        left: 0;
        right: 0;
    }
}
</style>
