<template>
    <div class="bg-white p-5 shadow-md rounded-lg">
        <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 px-3 mb-3">
                        <span clas="text-green-500">
                            <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
                            </svg>
                        </span>
            <span class="tracking-wide">Профиль</span>
            <button class="block bg-gray-200 p-2 hover:bg-gray-300 rounded group flex items-center gap-3"
                    @click="editing = !editing">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="h-4 w-4 fill-current">
                    <path
                        d="M481.996 30.006C462.647 10.656 436.922 0 409.559 0s-53.089 10.656-72.438 30.005L50.826 316.301a20 20 0 00-5.125 8.779L.733 486.637a20 20 0 0024.732 24.601l161.557-45.891a20 20 0 008.699-33.359L93.869 329.827 331.184 92.511l88.258 88.258-181.674 181.179c-7.821 7.8-7.838 20.463-.038 28.284 7.799 7.822 20.464 7.838 28.284.039l215.98-215.392C501.344 155.53 512 129.805 512 102.442s-10.656-53.088-30.004-72.436zM143.395 436.158L48.827 463.02l26.485-95.152 68.083 68.29zM453.73 146.575l-5.965 5.949-88.296-88.297 5.938-5.938C377.2 46.495 392.88 40 409.559 40c16.679 0 32.358 6.495 44.152 18.29C465.505 70.083 472 85.763 472 102.442c0 16.679-6.495 32.359-18.27 44.133z"/>
                </svg>
            </button>
        </div>
        <div class="text-gray-700">
            <div class="border-b border-gray-200 pb-3 mt-3 vue-quill-container" v-if="editing">
                <image-uploader :image-path="form.avatar"
                                label-title="Аватар" @emitFile="handleFileChange"></image-uploader>
            </div>
            <div class="grid md:grid-cols-2 text-sm gap-1">
                <div class="grid grid-cols-2">
                    <div class="px-3 py-2 font-semibold">Имя</div>
                    <div class="px-3 py-2" v-if="!editing">{{ form.first_name }}</div>
                    <input type="text" class="rounded-lg border border-gray-300 text-sm py-1 px-2" v-else
                           v-model="form.first_name">
                </div>
                <div class="grid grid-cols-2">
                    <div class="px-3 py-2 font-semibold">Фамилия</div>
                    <div class="px-3 py-2" v-if="!editing">{{ form.last_name }}</div>
                    <input type="text" class="rounded-lg border border-gray-300 text-sm py-1 px-2" v-else
                           v-model="form.last_name">
                </div>

                <div class="grid grid-cols-2">
                    <div class="px-3 py-2 font-semibold">Пол</div>
                    <div class="px-3 py-2" v-if="!editing">
                        <template v-if="form.gender">
                            {{ form.gender === 'm' ? 'Мужской' : 'Женский' }}
                        </template>
                    </div>
                    <select name="gender" v-model="form.gender" id="gender" class="rounded-lg border border-gray-300 text-sm py-1 px-2"
                            v-else>
                        <option :value="null"></option>
                        <option value="m">Мужской</option>
                        <option value="f">Женский</option>
                    </select>
                </div>

                <div class="grid grid-cols-2">
                    <div class="px-3 py-2 font-semibold">Телефон</div>
                    <div class="px-3 py-2" v-if="!editing">{{ form.phone }}</div>
                    <input type="text" class="rounded-lg border border-gray-300 text-sm py-1 px-2" v-else
                           v-model="form.phone">
                </div>

                <div class="grid grid-cols-2">
                    <div class="px-3 py-2 font-semibold">Email</div>
                    <div class="px-3 py-2" v-if="!editing">{{ form.email }}</div>
                    <input type="text" class="rounded-lg border border-gray-300 text-sm py-1 px-2" v-else
                           v-model="form.email">
                </div>

                <div class="grid grid-cols-2">
                    <div class="px-4 py-2 font-semibold">День рождения</div>
                    <div class="px-4 py-2" v-if="!editing">{{ form.birthday }}</div>
                    <date-picker input-class="rounded-lg border border-gray-300 text-sm py-1 px-2" value-type="format" format="DD.MM.YYYY" v-else
                                 v-model="form.birthday"></date-picker>
                </div>

            </div>
            <div class="flex px-3 gap-2" v-if="editing">
                <button class="block mt-3 py-2 px-4 rounded bg-green-300 hover:bg-green-400" @click="handleSubmit">
                    Сохранить
                </button>
                <button class="block mt-3 py-2 px-4 rounded border border-gray-300 hover:bg-gray-100"
                        @click="editing = false">Отменить
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ImageUploader from "../fields/ImageUploader";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import _ from "lodash";

export default {
    name: "ProfileInput",

    components: {
        ImageUploader,
        DatePicker
    },

    props: {
        user: {
            required: true
        }
    },

    data() {
        return {
            form: this.user,
            editing: false
        }
    },

    methods: {
        handleSubmit() {
            const formData = new FormData()
            _.keys(this.form).map(key => {
                formData.append(key, this.form[key])
            })
            axios.post(`api/users/${this.user.id}`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(res => {
                    this.editing = false
                    document.location.reload();
                })
                .catch(e => {
                    console.log('err', e)
                })
        },
        handleFileChange(val) {
            this.form.image = val
        },
    }
}
</script>
