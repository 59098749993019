<template>
    <button type="button" class="bg-gray-200 inline rounded-full py-1 px-2 text-xs" @click.prevent="showModal">
        <span class="text-green-500" v-if="moderated_data">Одобрен</span>
        <span class="text-red-400" v-else>Не одобрен</span>
    </button>
</template>

<script>
import ModalFeedbackStatusChange from './ModalFeedbackStatusChange'
import axios from "axios";

export default {
    name: "ModerateFeedback",

    props: {
        moderated: {
            required: false
        },
        id: {
            required: true
        },
        removeRoute: {
            required: true
        }
    },

    components: {
        ModalFeedbackStatusChange
    },

    data() {
        return {
            moderated_data: this.moderated
        }
    },

    methods: {
        showModal() {
            this.$modal.show(ModalFeedbackStatusChange,
                {
                    moderated: this.moderated
                },
                {
                    height: 'auto',
                    width: '400',
                    classes: 'md:ml-24'
                },
                {
                    'onSetStatus': async (status) => {
                        try {
                            await axios.post(`/api/feedbacks/${this.id}/set-status`, { status })
                            this.moderated_data = status
                        } catch (e) {
                            console.log(e)
                        }
                    },
                    'onDelete': async () => {
                        try {
                            await axios.delete(this.removeRoute)
                            document.location.reload()
                        } catch (e) {
                            console.log(e)
                        }
                    }
                })
        }
    }
}
</script>

