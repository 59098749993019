<template>
    <div>
        <label class="block text-sm font-medium text-gray-700" v-if="$attrs.label">{{ $attrs.label }}</label>
        <multiselect v-model="video_id" :options="videos" >
            <template v-slot:singleLabel="{option}">
                <div class="flex items-center gap-3" v-if="videos.length">
                    <img class="w-12 h-10 object-cover" :src="option.poster" alt="" />
                    <div class="option__desc">{{ option.original_filename }}</div>
                </div>
            </template>
            <template v-slot:option="{option}">
                <div class="flex items-center gap-3">
                    <img class="w-12 h-10 object-cover" :src="option.poster" alt="" />
                    <div class="option__desc">{{ option.original_filename }}</div>
                </div>
            </template>
        </multiselect>
        <input type="hidden" :name="$attrs.name" v-model="videoIdComputed">
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";

export default {
    name: "VideoSelect",

    components: { Multiselect },

    data() {
        return {
            video_id: this.$attrs.video_id ?? null,
            videos: [],
            query: ''
        }
    },

    computed: {
        videoIdComputed() {
            if (!this.video_id) {
                return null
            }
            if (typeof this.video_id === "string" || typeof this.video_id === "number") {
                return this.video_id
            }
            return this.video_id.id
        }
    },

    methods: {
        fetchVideos() {
            axios.get('/api/videos', {
                query: this.query
            }).then((res) => {
                if (res.data) {
                    this.videos = res.data.data
                }
                if(this.$attrs.video_id && this.videos.length) {
                    this.video_id = this.videos.find(item => item.id === parseInt(this.$attrs.video_id))
                }
            })
        }
    },

    mounted() {
        this.fetchVideos()
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
