import ModalRemoveConfirmationDialog from "./ModalRemoveConfirmationDialog";
import axios from "axios";

export const MODAL_REMOVE_METHODS = {
    props: {
        removeRoute: {
            required: true,
            type: String
        },
        successRoute: {
            required: false,
            type: String
        }
    },

    methods: {
        showModalConfirmation() {
            this.$modal.show(ModalRemoveConfirmationDialog, {},
                {
                    height: 'auto',
                    width: '320',
                    classes: 'md:ml-24 rounded-lg',
                },
                {
                    'onSubmit': async () => {
                        try {
                            await axios.delete(this.removeRoute)
                            if (this.successRoute) {
                                document.location.href = this.successRoute
                            }
                        } catch (e) {
                            console.log(e)
                        }
                    }
                })
        }
    }
}
